import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12.5 22.75H4.08c-1.57 0-2.86-1.26-2.86-2.82V5.09c0-1.53.57-2.72 1.61-3.37 1.05-.66 2.41-.64 3.82.06l4.44 2.18c1.21.6 2.16 2.11 2.16 3.45V22c0 .41-.34.75-.75.75m-7.94-20c-.35 0-.67.08-.94.25-.58.36-.9 1.11-.9 2.09v14.84c0 .73.61 1.32 1.36 1.32h7.67V7.41c0-.77-.62-1.76-1.33-2.1L5.98 3.13c-.51-.25-.99-.38-1.42-.38" }, null, -1),
    _createElementVNode("path", { d: "M18.81 22.75H12.5c-.41 0-.75-.34-.75-.75V10.42c0-.23.1-.44.28-.58s.4-.2.63-.15l.47.1 6.54 1.46c1.99.44 2.99 1.61 3.05 3.6.01 0 .01.1.01.21v3.78c-.01 2.56-1.36 3.91-3.92 3.91m-5.56-1.5h5.56c1.76 0 2.41-.65 2.41-2.41v-3.86c-.05-1.37-.57-1.98-1.88-2.27l-6.09-1.36zM8.97 9.75H5.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h3.47a.749.749 0 1 1 0 1.5m0 4H5.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h3.47a.749.749 0 1 1 0 1.5" }, null, -1),
    _createElementVNode("path", { d: "M15.22 17.75c-1.65 0-3-1.35-3-3v-4.23c0-.23.1-.44.28-.59s.41-.2.63-.15l4.5 1.01c.34.08.59.38.59.73v3.22c0 1.66-1.34 3.01-3 3.01m-1.5-6.29v3.29c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-2.62z" }, null, -1),
    _createElementVNode("path", { d: "M19.72 17.75c-1.65 0-3-1.35-3-3v-3.22c0-.23.1-.44.28-.58s.41-.2.63-.15l2.03.45c1.99.44 2.99 1.61 3.05 3.6v.06a2.99 2.99 0 0 1-2.99 2.84m-1.5-5.29v2.29a1.498 1.498 0 0 0 2.99.11c-.05-1.26-.57-1.86-1.87-2.15z" }, null, -1)
  ])))
}
export default { render: render }